<template>
  <div class="maindiv">
      <div id="page1-wrapper" v-if="pageOne" class="diversity-list">
        <Questioncard v-for="card in cards.slice(0, 9)" :question="card" :questionurl="card.frageurl" :answerurl="card.antworturl" :key="card.id" class="diversity-item"></Questioncard>
      </div>
      <div id="page1-wrapper" v-else class="diversity-list">
        <Questioncard v-for="card in cards.slice(9, 18)" :question="card" :questionurl="card.frageurl" :answerurl="card.antworturl" :key="card.id" class="diversity-item"></Questioncard>
      </div>
      <div class="warm-grey-on-yellow">
        <v-btn class="card-footer-item" :class="{toggled: pageOne}" @click="pageOne = !pageOne">
          <a href="#">Weitere Fragen</a>
        </v-btn>
      </div>
  </div>
</template>

<script>
import Questioncard from "./Questioncard.vue"
export default {
    name: "cardlist",
    props: {
      msg: String,
    },
    data() { 
      return {
        pageOne: true,
        cards: [
          {
            id: 1,
            name: "GenX",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/1-GenX-Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/1-GenX-Antwort.jpg"
          },
          {
            id: 4,
            name: "A7175",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/1-%C2%A7175-Frage-jpg.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/1-%C2%A7175-Antwort.jpg"
          },
          {
            id: 14,
            name: "BiPoc",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/1_bipoc_Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/1_bipoc_Antwort.jpg"
          },
          {
            id: 7,
            name: "BTHG",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/1-BTHG-Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/1-BTHG-Antwort.jpg"
          },
          {
            id: 17,
            name: "Zuckerfest",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/2-zuckerfest_Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/2_zuckerfest_Antwort.jpg"
          },
          {
            id: 9,
            name: "Cochlea Implantat",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/2-CochleaImplantat-Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/2-CochleaImplantat-Antwort.jpg"
          },
          {
            id: 10,
            name: "Queer",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/1-Queer_Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/1-queer_Antwort.jpg"
          },
          {
            id: 2,
            name: "Arbeitsmarkt",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/2-Arbeitsmarkt-Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/2-Arbeitsmarkt-Antwort.jpg"
          },
          {
            id: 11,
            name: "Transsexualität",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/1-transsexualitaet_Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/1-transsexualitaet_Antwort.jpg"
          },
          {
            id: 12,
            name: "CIS Gender",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/2-cisgender_Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/2-cisgender_Antwort.jpg"
          },
          {
            id: 3,
            name: "GenGap",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/1-GenGap-Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/1-GenGap-Antwort.jpg"
          },
          {
            id: 13,
            name: "Rosa Parks",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/2_RosaParks_Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/2_RosaParks_Antwort_0.jpg"
          },
          {
            id: 15,
            name: "Paprikasauce ungarischer Art",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/2_zigeunersuace_Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/2_Zigeunersauce_Antwort.jpg"
          },
          {
            id: 5,
            name: "LGBTQA",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/2-LGBTQAI%2B-Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/2-LGBTQAI%2B-Antwort.jpg"
          },
          {
            id: 16,
            name: "Orthodoxe Kirche",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/2_orthodoxeKirche_Frage_0.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/2_orthodoxeKirche_Antwort_0.jpg"
          },
          {
            id: 6,
            name: "Ally",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/2-Ally-Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/2-Ally-Antwort.jpg"
          },
          {
            id: 8,
            name: "Arbeitsplätze",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/1-Arbeitspl%C3%A4tze-Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/1-Arbeitspl%C3%A4tze-Antwort.jpg"
          },
          {
            id: 18,
            name: "Jom Kippur",
            frageurl: "https://www.de.weber/files/de/pictures/2022-05/1-JomKippur_Frage.jpg",
            antworturl:"https://www.de.weber/files/de/pictures/2022-05/1_JomKippur_Antwort.jpg"
          },       
        ]
      }
    },
    components: { Questioncard }
}
</script>
<style scoped>

.diversity-list {
  padding-top: 2em;
  display: grid;
  grid-gap: 1em;
}

@media (min-width: 900px) {
  .diversity-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1150px) {
  .diversity-list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.diversity-item {
  padding: 0.5em;
  text-align: center;
  display: flex;
  flex-direction: column;
}

</style>