<template>
  <div class="Card">
    <div class="flipbox" ontouchstart="this.classList.toggle('hover');">
      <div class="flipper">
        <div class="front">
          <img :src="questionurl" alt="front" class="diversity-img">
        </div>
        <div class="back">
          <img :src="answerurl" alt="back" class="diversity-img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'questioncard',
  props: {
    msg: String,
    question: Object,
    questionurl: String,
    answerurl: String
  },

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 20px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 5px;
}
a {
  color: #42b983;
}
img { width: 100%; height:auto}

.diversity-img {
  border-radius: 12px 44px;
  border-width: 4px;
  border-style: solid;
  border-color: black;
}

.flipbox {
  perspective: 2000px; 
  max-width: 600px; 
  margin-left:auto; margin-right:auto;
  margin-top: 0.5em; 
}
.flipbox:hover .flipper, .flipbox.hover .flipper { 
  transform: rotateY(180deg); 
}
.flipbox, .front, .back {
  width: 270px;height: 151px; 
}

/** Für größere Viewports **/
@media (min-width: 640px) {
  .flipbox, .front, .back {
    width: 350px;height: 225px; 
  }
}

.flipper {
  position: relative;
  transition: 0.5s;
  transform-style: preserve-3d;
}

.front, .back {
  position: absolute;top: 0;left: 0;
  -webkit-backface-visibility:hidden;
  backface-visibility: hidden;
}

.front {transform: rotateY(0deg);}
.back {transform: rotateY(180deg);}
</style>
