<template>
  <div>
    <Cardlist msg="Willkommen zum Diversity-Quiz" />
  </div>
</template>

<script>

import Cardlist from './components/Cardlist.vue'

export default {
  name: 'App',
  components: {
    Cardlist
},
}
</script>

<style>

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}

</style>
